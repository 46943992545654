<template>
    <!-- Start Feature #1-->
    <section class="features text-center" id="HowItWorks">
        <div class="container">
            <div class="row clearfix">
                <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <div class="heading text-center">
                        <h2 class="heading-title">Cara Kerja QRCBN</h2>
                        <p class="heading-desc">Bagaimana QRCBN bekerja</p>
                    </div>
                </div>
                <!-- End .col-lg-6 -->
            </div>
            <!-- End .row  -->
            <div class="row">
                <!-- Panel #1  -->
                <div class="col-12 col-md-12 col-lg-4 ">
                    <div class="feature-panel">
                        <div class="feature-icon text-center bg-section"
                            style="background-image: url(&quot;assets/images/icons/bg-icon-1.svg&quot;);">
                            <!-- <img src="assets/images/icons/icon-dollar.svg" alt="Dollar Icon"> -->
                            1
                        </div>
                        <div class="feature-content">
                            <img src="@/assets/step-1.png" alt="Delivery Icon" width="50%" style="margin-bottom: 30px;">
                            <p>Setiap Buku yang diterbitkan Akan Mendapatkan QR Code Sebagai Identikasi Setiap Buku, QR Code Bisa Dipasang Dimanapun sebagai media Promosi, misal di Cover Buku & Website Penjualan.</p>
                        </div>
                    </div>
                    <!-- End .feature-panel -->
                </div>
                <!-- End .col-md-12-->
                <!-- Panel #2  -->
                <div class="col-12 col-md-12 col-lg-4 ">
                    <div class="feature-panel">
                        <div class="feature-icon text-center bg-section"
                            style="background-image: url(&quot;assets/images/icons/bg-icon-2.svg&quot;);">
                            2
                            <!-- <img src="assets/images/icons/icon-cup.svg" alt="Cup Icon"> -->
                        </div>
                        <div class="feature-content">
                            <img src="@/assets/step-2.png" alt="Delivery Icon" width="50%" style="margin-bottom: 30px;">
                            <p>QR Code Dapat Dipindai Melalui Website Resmi QRCBN atau Menggunakan Aplikasi Pemindai QR Code yang Lainnya.</p>
                        </div>
                    </div>
                    <!-- End .feature-panel -->
                </div>
                <!-- End .col-md-12-->
                <!-- Panel #3-->
                <div class="col-12 col-md-12 col-lg-4 ">
                    <div class="feature-panel">
                        <div class="feature-icon text-center bg-section"
                            style="background-image: url(&quot;assets/images/icons/bg-icon-3.svg&quot;);">
                            <!-- <img src="assets/images/icons/icon-delivery.svg" alt="Delivery Icon"> -->
                            3
                        </div>
                        <div class="feature-content">
                            <img src="@/assets/step-3.png" alt="Delivery Icon" width="50%" style="margin-bottom: 30px;">
                            <p>Setelah QR Code Terverifikasi Oleh Sistem, maka Selanjutnya Akan Otomatis Diarahkan Ke Halaman Informasi Detail Dari Buku Dengan Kode Identifikasi Sesuai Dengan QR Code yang Dipindai.</p>
                        </div>
                    </div>
                    <!-- End .feature-panel -->
                </div>
                <!-- End .col-md-12-->
            </div>
            <!-- End .row  -->
        </div>
        <!-- End .container-->
    </section>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
};
</script>