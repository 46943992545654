<template>
    <div>
        <form class="mb-0 form-action mailchimp" @submit.prevent="checkId">
            <div class="input-group">
                <input class="form-control" type="text" placeholder="Nomor Identifikasi QRCBN" required="required" v-model="qrcbn_id"/>
                <button class="btn btn--secondary" type="submit"><span>Lihat Naskah</span></button>
            </div>
            <!--  End .input-group-->
        </form>
        <div class="subscribe-alert"></div>
    </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    qrcbn_id: "",
  }),
  methods: {
    checkId() {
      var id = this.qrcbn_id;

      if (id == "") {
        this.openNotification(
          "top-right",
          "danger",
          "10000",
          "Gagal",
          "Nomor Identitas Buku Tidak Boleh Kosong"
        );
      } else {
        this.$router.push({ name: "Check", params: { id: id } });
      }
    },
  },
};
</script>