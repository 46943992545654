<template>
    <!-- Start Feature #1-->
    <section class="features text-center" id="benefit">
        <div class="container">
            <div class="row clearfix">
                <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <div class="heading text-center">
                        <h2 class="heading-title">Keuntungan QRCBN</h2>
                        <p class="heading-desc">Kenapa buku kamu harus didaftarkan dan memiliki QRCBN? <br> Apa keuntungan yang akan kamu dapatkan?</p>
                    </div>
                </div>
                <!-- End .col-lg-6 -->
            </div>
            <!-- End .row  -->
            <div class="row">
                <!-- Panel #1  -->
                <div class="col-12 col-md-12 col-lg-4 ">
                    <div class="feature-panel">
                        <div class="feature-icon text-center bg-section"
                            style="background-image: url(&quot;assets/images/icons/bg-icon-1.svg&quot;);">
                            <img src="assets/images/icons/icon-dollar.svg" alt="Dollar Icon">
                        </div>
                        <div class="feature-content">
                            <h3>Informatif</h3>
                            <p>Dengan teknologi QR Code, QRCBN dapat memuat informasi detail mengenai buku kamu, sehingga dapat dijadikan sebagai media promosi.</p>
                        </div>
                    </div>
                    <!-- End .feature-panel -->
                </div>
                <!-- End .col-md-12-->
                <!-- Panel #2  -->
                <div class="col-12 col-md-12 col-lg-4 ">
                    <div class="feature-panel">
                        <div class="feature-icon text-center bg-section"
                            style="background-image: url(&quot;assets/images/icons/bg-icon-2.svg&quot;);">
                            <img src="assets/images/icons/icon-cup.svg" alt="Cup Icon">
                        </div>
                        <div class="feature-content">
                            <h3>Mudah Ditemukan</h3>
                            <p>QRCBN memiliki marketplace buku sendiri, yang akan membuat buku kamu mudah ditemukan oleh pembaca.</p>
                        </div>
                    </div>
                    <!-- End .feature-panel -->
                </div>
                <!-- End .col-md-12-->
                <!-- Panel #3-->
                <div class="col-12 col-md-12 col-lg-4 ">
                    <div class="feature-panel">
                        <div class="feature-icon text-center bg-section"
                            style="background-image: url(&quot;assets/images/icons/bg-icon-3.svg&quot;);">
                            <img src="assets/images/icons/icon-delivery.svg" alt="Delivery Icon">
                        </div>
                        <div class="feature-content">
                            <h3>Cepat Dikenal</h3>
                            <p>Dengan berbagai fitur QRCBN, Buku kamu akan cepat dikenal, dan kamu akan jadi penulis terkenal.</p>
                        </div>
                    </div>
                    <!-- End .feature-panel -->
                </div>
                <!-- End .col-md-12-->
            </div>
            <!-- End .row  -->
        </div>
        <!-- End .container-->
    </section>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
};
</script>