<template>
    <!-- 
      CTA #1
      =============================================  
      -->
    <section class="cta pt-0 pb-0" id="action">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-12">
                    <div class="action-container" style="background-image: url('assets/images/background/bg-cta.png');">
                        <div class="row align-items-center h-100">
                            <div class="col-12 col-md-4 col-lg-4">
                                <div class="cta-cover"><img class="img-fluid" src="assets/images/cover/book-cover-2.png"
                                        alt="book cover" /></div>
                            </div>
                            <div class="col-12 col-md-8 col-lg-8">
                                <h3>Lihat Detail Buku Kamu ? <br> Masukkan Nomor QRCBN.</h3>
                                <div class="form-action">
                                    <form class="mb-0 form-action mailchimp">
                                        <div class="input-group">
                                            <input class="form-control" type="text"
                                                placeholder="Nomor Identifikasi QRCBN" required="required" />
                                            <button class="btn btn--secondary"><span>lihat naskah</span></button>
                                        </div>
                                        <!--  End .input-group-->
                                    </form>
                                    <div class="subscribe-alert"></div>
                                </div>
                                <a href="/scan">
                                    <button class="btn btn--secondary">Scan QR Code</button>
                                </a>
                            </div>
                            <!-- End .col-md-12-->
                        </div>
                        <!-- End .row-->
                    </div>
                </div>
            </div>
        </div>
        <!-- End .container-->
    </section>
    <!-- End #cta-->
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
};
</script>