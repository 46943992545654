<template>

  <div id="home">
    <jumbotron/>
    <benefit/>
    <statistic/>
    <how-it-works/>
    <scan/>
    
    <div class="text-center mt-3">
      <Adsense
        data-ad-client="ca-pub-3076489163946110"
        data-ad-slot="6109811926">
      </Adsense>
    </div>

  </div>

</template>

<script>

import jumbotron from '@/components/public_home/Jumbotron'
import Benefit from '@/components/public_home/Benefit.vue'
import Statistic from '@/components/public_home/Statistic.vue'
import HowItWorks from '@/components/public_home/HowItWorksNew.vue'
import Scan from '@/components/public_home/Scan.vue'
export default {
  components: {
    // ComingSoon,
    jumbotron,
    Benefit,
    Statistic,
    HowItWorks,
    Scan
  }
}
</script>

<style scoped>
@import '../../public/assets/css/vendor.min.css';
@import '../../public/assets/css/style.css';
</style>