<template>
  <!-- Start hero #1-->
  <section class="hero hero-mailchimp bg-primary-alt new-hero" id="hero">
    <div class="container">
      <div class="hero-cotainer">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="hero-content">
              <h1 class="hero-headline">Segera Diluncurkan.</h1>
              <div class="hero-bio">Aplikasi ebook QRCBN Market, sebagai wadah Penerbit menjual e-book terbitannya & menjangkau pembaca lebih luas.</div>
              <!-- <div class="hero-bio" style="color: #ff7101;">Sekarang kamu bisa menjual bukumu dalam bentuk ebook di market QRCBN</div> -->
              <div class="hero-action text-center">
                <search/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6"><img class="img-fluid float-right" src="assets/images/cover/newBanner.png"
              alt="Book Cover" /></div>
        </div>
        <!-- End .row-->
      </div>
      <!-- End .hero-cotainer-->
    </div>
    <!-- End .container-->
    <!-- <div class="divider-shape-bottom"><svg viewBox="0 0 1920 250" xmlns="http://www.w3.org/2000/svg"
        style="background: #2D36A1" width="100%" preserveAspectRatio="none">
        <path fill="rgb(244, 246, 249, 0.02)"
          d="M1920 250H0V0s126.707 78.536 349.975 80.05c177.852 1.203 362.805-63.874 553.803-63.874 290.517 0 383.458 57.712 603.992 61.408 220.527 3.696 278.059-61.408 412.23-17.239">
        </path>
        <path fill="rgb(244, 246, 249, 0.08)"
          d="M1920 144s-467.917 116.857-1027.243-17.294C369.986 1.322 0 45.578 0 45.578V250h1920V144z"></path>
        <path fill="#FFFFFF"
          d="M0 195.553s208.547-75.581 701.325-20.768c376.707 41.908 520.834-67.962 722.545-67.962 222.926 0 311.553 83.523 496.129 86.394V250H0v-54.447z">
        </path>
      </svg>
    </div> -->
  </section>
  <!-- End #hero-->
</template>

<script>
import Search from './Component/Search.vue';
export default {
  components: {Search},
  data() {
    return {};
  },
};
</script>

<style>
.new-hero{
  padding-bottom: 0 !important;
}
</style>